module.exports = {
  siteTitle: 'Mohan C M | Software Engineer',
  siteDescription:
    'Mohan C M is a software engineer based in Bengaluru, India who specializes in building (and occasionally designing) exceptional websites, applications, and everything in between.',
  siteKeywords:
    'Mohan C M, Mohan, cm, mohancm, mohancm100, software engineer, software developer, web developer, android developer, indian, mohan, developer, dotos, droidontime',
  siteUrl: 'https://www.mohancm.me/',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-163936220-2',
  googleVerification: 'we8BAy0K74dRMWWU3hIADbx0wA9s4Jw-W_DSe4A9gsI',
  name: 'Mohan C M',
  location: 'Bengaluru, India',
  email: 'mohanmanjappa@gmail.com',
  github: 'https://github.com/mohancm',
  twitterHandle: '@ImMohanCm',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/mohancm',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/mohancm',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/mohancmofficial',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/ImMohanCm',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
