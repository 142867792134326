import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
      <path d="M37.3,51.4h-1.5L24.6,30.2v29.6h-1.4V27.6h1.6L37.3,51.4z M26,34.9l1.4,2.7v22.2H26V34.9z M40.5,51.4h-1.6
			L26.3,27.6h1.6L40.5,51.4z M28.8,40.2l1.4,2.7v16.9h-1.4C28.8,59.8,28.8,40.2,28.8,40.2z M54.8,30.2L43.6,51.4H42L29.5,27.6h1.6
			l11.8,22.3l11.7-22.3h1.6v32.3h-1.4C54.8,59.9,54.8,30.2,54.8,30.2z M31.5,45.6l1.4,2.7v11.6h-1.4V45.6z M40.9,44.3l-0.8-1.5
			l8.1-15.3h1.6L40.9,44.3z M42.6,47.4L41.8,46l9.7-18.4H53L42.6,47.4z M46.3,48l1.4-2.7v14.4h-1.4C46.3,59.8,46.3,48,46.3,48z
			 M49.2,42.7l1.4-2.7v19.8h-1.4V42.7z M52,37.4l1.4-2.7v25.1H52V37.4z" fill="currentColor"/>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
